.news-slide{
    .overlaybg{
            height: 380px;
            overflow: hidden;
            background-color: $gray-800;
        img{
            width: 100%;
        }
    }  
} 
