/*******************/
/*Comment widgets*/
/*******************/

.comment-widgets {
    position: relative;
    margin-bottom: 10px;
    .comment-row {
        border-bottom:1px solid transparent;
        padding: 14px;
        &:last-child{
            border-bottom:0px;
        }
        &:hover,
        &.active {
            background:$gray-100;
        }
    }
}

.comment-text {
    padding: 14px 15px 14px 20px;
    width:80%;
    &:hover .comment-footer,
    &.active .comment-footer {
        .action-icons {
            visibility: visible;
        }
    }
    p {
        max-height: 65px;
        width:100%;
        overflow: hidden;
        
    } 
}

.comment-footer {
    .action-icons {
        visibility: hidden;
        a {
            padding-left: 7px;
            vertical-align: middle;
            color: $text-muted;
            &:hover,
            &.active {
                color: $info;
            }
        }
    }
}


