/*******************/
/*Chat widget*/
/*******************/

.chat-list {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        margin-top: 30px;
        .chat-img {
            display: inline-block;
            width: 45px;
            vertical-align: top;
            img {
                width: 45px;
                border-radius: 100%;
            }
        }
        .chat-content {
            width: calc(100% - 50px); 
            display: inline-block;
            padding-left: 15px;
            h5 {
                color: $text-muted;
            }
            .box {
                display: inline-block;
                
                color:$gray-800;
                background: $gray-100;
            }
        }
        .chat-time {
            display: block;
            font-size: 10px;
            color: $text-muted;
            margin:5px 0 15px 65px;
        }
        &.odd {
            .chat-content {
                text-align: right;
                width: calc(100% - 0px);
            }
            .chat-time{
                text-align:right;
            }
            .box {
                clear: both;
                color:$white;
                background: $themecolor;
            }
        }
        &.odd + .odd {
            margin-top: 0px;
        }
        &.reverse {
            text-align: right;
            .chat-time {
                text-align: left;
            }
            .chat-content {
                padding-left: 0px;
                padding-right: 15px;
            }
        }
    }
}


/*******************/
/*Chat widget*/
/*******************/





