// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';



//Eliteadmin Styles
@import '../vendor/eliteadmin/scss/style.scss';

//custom styles
@import 'extra';
@import 'frontend'; //register pages
@import 'components'; //view components

//jquery-ui
@import '~jquery-ui/themes/base/core.css';

//perfect-scrollbar (external cs)
@import '../vendor/perfect-scrollbar/perfect-scrollbar.min.css';

//various modules
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@import '../vendor/timepicker-addon/timepicker-addon.css';
@import '../vendor/user-friendly-timepicker/css/timepicker.min.css';
@import '~sweetalert2/dist/sweetalert2.css';
// @import '~morris.js/morris.css';
@import '~select2/dist/css/select2.css';
@import "~toastr";
@import "~jquery-steps/demo/css/jquery.steps.css"; //todo: I think we don't use this
@import "~jquery-steps/demo/css/main.css"; //todo: I think we don't use this


//todo: which one?
//@import "~datatables/media/css/jquery.dataTables.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";

//select2 optgroups plugin
@import '../vendor/select2-optgroups/select2.optgroupSelect.css';


@import "~choices.js/public/assets/styles/choices.min.css";
 
//quill editor
@import "quill/dist/quill.snow.css"; 