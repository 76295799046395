@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


/**********************
 login/register pages
*********************/


/* LOGIN / REGISTER STYLES */

.login-register {


  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-style: normal;

  background: rgb(255, 255, 255);
  //background: linear-gradient(90deg, rgba(255, 255, 255, 1) 67%, rgba(0, 134, 233, 1) 67%);


  min-height: 100vh;
  position: relative;


  @media screen and (max-width: 768px) {
    background: none;
  }

  //@media screen and (min-width: 768px) and (max-width: 1024px) {
  //  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(0, 134, 233, 1) 70%);
  //
  //}

  .alerts {

    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .scroll-sidebar {
    padding: unset;
    position: relative !important;
  }
}


.register-container {

  //max-width: 80%;
  //margin-left: 10%;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .menu {
    width: 60px;
    flex-shrink: 0;
    background: #0086E9;
    box-shadow: 5px 0px 9px rgba(0, 0, 0, 0.25);

    height: 100%;
    font-size: 20px;
    color: #ffffffaa;

    ul {

      margin-bottom: 40px;

      li {
        width: 60px;

        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: block;

        &.logo {
          margin-top: 10px;
          margin-bottom: 80px;
        }

        &.empty {
          height: 50px;
        }
      }
    }
  }

  .col-left {
    //flex: 1;
    display: flex;
    align-items: center;
    padding-left: 50px;

    position: relative;

    width: 40%;
    padding-left: 10%;
    //max-width: 400px;
    min-width: 370px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
      padding-left: 50px;
    }


  }

  .col-right {
    flex: 1;
    display: flex;
    align-items: center;
    //padding-right: 50px;

    //background: linear-gradient(90deg, rgba(255, 255, 255, 1) 67%, rgba(0, 134, 233, 1) 67%);
    //background-color: #0086e9;
    height: 100vh;

    @media screen and (max-width: 768px) {
      padding-right: 0;
      padding-bottom: 60px;
      background-color: #0086e9;

    }

    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 250px;
      top: 0;
      bottom: 0;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        left: 120px;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }

      background-color: #0086e9;
      background-repeat: no-repeat;
      background-position: center center;

    }

    .content-block {
      max-width: unset;
    }

  }

  .blue-background {

    @media screen and (max-width: 768px) {
      display: none;
    }

    position: absolute;
    top: 0;
    bottom: 0;
    //left: 0;
    z-index: 1;

    img {
      height: 100%;
      width: 250px;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        width: 120px;
      }

    }


  }


  .content-block {

    max-width: 460px;

    position: relative;
    z-index: 2;

    @media screen and (max-width: 768px) {
      margin: 40px 20px;
    }

    img.people {
      margin-top: 20px;
      margin-bottom: 40px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

    }

    .main-text {
      font-size: 40px;
      font-weight: bold;
      line-height: 120%;
      color: #fff;

      max-width: 550px;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }


      @media screen and (min-width: 768px) and (max-width: 1024px) {
        padding-left: 120px;
      }

      @media screen and (min-width: 1024px) {
        padding-left: 250px;
      }

    }

    .slogan {
      //font-family: Josefin Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;

      width: max-content;
    }

    .logo {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .fields {

      label {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;

        margin-bottom: 0;
      }

      input, select {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;

        margin-bottom: 30px;
        color: #6c757d;
      }

    
 
      // .form-control:focus {
      //   color: #4F5467;
      //   background-color: #fff;
      //   border-color: rgba(0, 0, 0, 0.1);
      //   outline: 0;
      //   box-shadow: transparent; }
      // .form-control::placeholder {
      //   color: #6c757d;
      //   opacity: 1; }
      // .form-control:disabled, .form-control[readonly] {
      //   background-color: #e9ecef;
      //   opacity: 1; }

      button {
        margin-top: 20px;
        margin-bottom: 40px;

        font-size: 20px;

      }


      .agree {
        font-size: 12px;
      }

      .login, .invitation {

        margin-top: 30px;
        font-size: 12px;

        a {
          color: #0086E9;
        }
      }

      .invited {
        padding: 11px;
        border: 1px solid #0086e9;
        border-radius: 15px;
        font-weight: bold;

        margin-bottom: 20px;
      }


      &.double {
        display: flex;
        flex-wrap: wrap;

        .field {
          width: 50%;

          &.companyname,
          &.submit {
            width: 100%;
          }
        }
      }
    }

    .bottom-text {
      margin-top: 20px;

      font-size: 18px;
      line-height: 71.5%;

      a {
        color: #0086E9;
      }
    }

  }

  .copyright {
    position: absolute;
    bottom: 20px;
    right: 50px;

    span {
      color: #fff;
      margin-right: 30px;
    }
  }

}

.btn,
button {

  &.colorful {
    background: linear-gradient(90deg, #81D21D 0%, #0086E9 100%);
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 68px;
    padding: 8px 40px;
    color: #fff;
  }

}

.colorful-text {

  background: #81D21D;
  background: -webkit-linear-gradient(to right, #81D21D 10%, #0086E9 90%);
  background: -moz-linear-gradient(to right, #81D21D 10%, #0086E9 90%);
  background: linear-gradient(to right, #81D21D 10%, #0086E9 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

/*************************
lead details, comments and protests
**************************/


.lead-comments-accordion {

  .card-header {

    /*    &[aria-expanded="true"] {*/

    &.collapsed {

      .actions {
        display: none;
      }

    }
  }
}
