/* dashboard projects */

.dashboard-projects {

  font-size: 13px;


  table {

    th {
      border: none;
      font-weight: bold;
    }

    tr {

    }

    td {
      border: none;
      padding: 10px;

      vertical-align: middle;
      color: #626262;
    }

  }

  .progress {
    height: 10px;

    .progress-bar {
      background: linear-gradient(90deg, #81D21D 0%, #0086E9 100%);
      height: 10px;
    }
  }

  .new{
    background: linear-gradient(90deg, #FFB168 0%, #FB3333 100%);

    width: 20px;
    height: 20px;
    display: block;
    border-radius: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 20px;
  }
}