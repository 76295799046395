[x-cloak] {
  display: none !important;
}

/* resets */

body {
  font-size: 15px;
}

strong {
  font-weight: bold;
}

/* global little helpers */

.mw-200 {
  max-width: 200px;
}

.mw-300 {
  max-width: 300px;
}

.mw-400 {
  max-width: 400px;
}

.mw-600 {
  max-width: 600px;
}

.mw-800 {
  max-width: 800px;
}

.oh {
  overflow: hidden;
}

.oh-nice {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label-large {
  padding: 8px 12px;
  font-size: 16px;
}

.label-disabled {
  background-color: #9c9c9c;
}

/* */

.login-register nav {
  position: absolute;
  top: 0;
  right: 0;
}

.navbar-nav .nav-link,
.navbar-nav .nav-link.active {
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  margin-left: 10px;
}

.login-register .alerts {
  max-width: 800px;
  margin: 0 auto;
}

.login-register .login-brand {
  background-color: #fff;
  display: block;
  padding: 14px;
  /*margin-left: -10px;*/
  text-align: center;
  font-size: 30px;
  color: #333;
  width: 100%;
}

.login-register .login-brand img.dark-logo {
  filter: brightness(0%);
}

@media screen and (min-width: 768px) {
  .login-box {
    max-width: 800px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .login-box {
    display: block;
    margin-top: 10px;
    margin-left: -10px;
    width: unset;
  }
}

/*profile */
.card.profile img.bedrijfslogo {
  max-height: 150px;
  width: auto;
}

.card.profile .card-img-overlay {
  height: 300px;
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: linear-gradient(#fb9678, #fb9678),
    linear-gradient(#e9ecef, #e9ecef);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  float: none;
}

.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

/*immowi project index/list*/

.card-img-overlay {
  bottom: unset !important;
}

.card-img-top-img-container {
  height: 300px;
  background-size: cover;
  background-position: center;

  .card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*leads aanvragen frontend*/

.leads-aanvragen .btn {
  margin: 15px;
}

.leads-aanvragen .tab-content {
  border: 0;
  padding: 30px;
}

.fixed-layout .page-wrapper {
  padding-top: 60px;
}

/*pagination*/

.paginate_button {
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fb9678;
  background-color: #fff;
  border: 1px solid #dee2e6;
  display: inline-block;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active .page-link,
.paginate_button.current {
  z-index: 1;
  color: #fff;
  background-color: #fb9678;
  border-color: #fb9678;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.paginate_button:hover {
  z-index: 2;
  color: #f95c2e;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.breadcrumb,
.pagination {
  border-radius: 0.25rem;
  list-style: none;
  margin-bottom: 10px !important;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-titles .btn {
  margin-top: 10px;
}

/* credits card in top bar*/
.card-body .aanvragen_boven {
  text-align: right;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px;
}

@media screen and (min-width: 992px) and (max-width: 1500px) {
  .card-body .aanvragen_boven {
    top: 0px;
    right: 5px;
  }
}

@media screen and (min-width: 576px) and (max-width: 750px) {
  .card-body .aanvragen_boven {
    top: 0px;
    right: 5px;
  }
}

.card-body .gereserveerd {
  position: absolute;
  width: 100%;
  right: 0;
  text-align: right;
  padding-right: 20px;
  bottom: 10px;
}

/* end credits card in top bar*/

.img-preview {
  height: 60px;
}

.img-preview img {
  height: 60px;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: unset;
  color: #fff;
  cursor: default;
}

.container.lightbox {
  /*max-width: 500px;*/
  max-width: unset;
  width: unset;
}

/*credits buy page*/

.credit-calculator__button {
  box-shadow: none;
}

#invoice_button {
  display: block;
}

#invoice_button:disabled {
  display: none;
}

#online_button:disabled {
  background-color: #dbdada;
  color: #8a8a8a;
}

#online_button:disabled:hover {
  box-shadow: none;
}

/* project stylesheet colors */

.ss_select.live {
  background-color: rgba(0, 255, 0, 0.1);
  border-color: rgba(0, 255, 0, 1);
}

.ss_select.klaar {
  background-color: rgba(255, 255, 0, 0.1);
  border-color: rgba(255, 255, 0, 1);
}

.ss_select.in_progress {
  background-color: rgba(255, 0, 0, 0.1);
  border-color: rgba(255, 0, 0, 1);
}

.ss_select.nvt {
  background-color: rgba(181, 174, 174, 0.1);
  border-color: rgba(181, 174, 174, 1);
}

.ss_select.afgelopen {
  background-color: rgba(0, 0, 255, 0.1);
  border-color: rgba(0, 0, 255, 1);
}

.ss_select.in_afwachting {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 1);
}

.sidebar-nav ul li ul li a {
  padding-right: 5px;
}

.sidebar-nav ul li a {
  padding: 10px 10px 10px 15px;
}

.card.postcodes {
  border: 1px solid #999;
}

.card.postcodes .form-group {
  margin-bottom: 3px;
}

.card.restrictions {
  border: 1px solid #ff3333;
}

.card.restrictions .header {
  background-color: #ff999933;
}

.card.restrictions .form-group {
  margin-bottom: 3px;
}

.admin #lead_price {
  background-color: #fec;
}

.language-selector .current {
  font-size: 14px;
  color: #efefef;
}

.language-selector .flag {
  margin-left: 10px;
  width: 30px;
}

.language-selector .dropdown-menu .lang {
}

.language-selector .dropdown-menu .flag {
  float: right;
  width: 30px;
  height: auto;
}

.text-muted-lighter {
  color: #dedede;
}

#edit_landingpage .checkboxen,
#create_landingpage .checkboxen {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #dedede;
}

form .cost-options {
  background-color: #f9f9f9;
  padding-top: 10px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
}

.notfullwidth {
  width: unset !important;
}

table.dataTable th.filterable input {
  width: 100%;
  min-width: 150px;
}

.row.select-manager {
  background-color: #f9f9f9;
  padding-top: 10px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
}

.form-group.delete-afbeelding {
  position: relative;
  bottom: 44px;
}

.card-body.title_postcodes {
  max-height: 276px;
  overflow: hidden;
}

/* introjs stuff */

.introjs-helperLayer {
  background: transparent;
}

.introjs-overlay {
  opacity: 0 !important;
}

.introjs-helperLayer:before {
  opacity: 0;
  content: "";
  position: fixed;
  width: inherit;
  height: inherit;
  border-radius: 0.5em;
  box-shadow: 0 0 0 1000em rgba(0, 0, 0, 0.7);
  opacity: 1;
}

.introjs-helperLayer:after {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

/* responsive datatables */

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child::before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child::before {
  top: 19px;
}

tr.child ul.dtr-details {
  width: 100%;
}

/*tr.child ul.dtr-details li { width: 100%;}*/
tr.child ul.dtr-details .dtr-title {
  min-width: 125px !important;
  width: 25%;
  display: inline-block;
}

tr.child ul.dtr-details .dtr-data {
  display: inline-block;
}

/* datatables length menu */
.dataTables_length {
  padding-left: 15px;
  color: #f95c2e;
  border: 1px solid #dee2e6;
  height: 35px;
  margin-bottom: 20px;
  margin-right: 0px;
  margin-left: 5px;
  line-height: 30px;
  padding-right: 10px;
}

.dataTables_length,
.dataTables_paginate {
  float: right;
  margin-top: 10px !important;
}

/* email template editor */

.summernote-toolbar {
  text-align: center;
}

#templated-content {
  background-color: #efefef;
  padding: 30px;
}

#templated-content .container {
  background-color: #ffffff;
}

#templated-content .container table {
  /*border: 1px dotted #eeeeee;*/
}

#templated-content .note-editor table,
#templated-content .note-editor td,
#templated-content .note-editor tr {
  border: 1px dotted #5f7fdd;
}

#templated-content .note-editor td {
  padding: 10px;
}

#templated-content #layout-header,
#templated-content #layout-content,
#templated-content #layout-signature,
#templated-content #layout-footer {
  border: 1px dashed #ff0000;
  /*margin: -20px;*/
  /*padding: 20px;*/
}

/* admin add lead - extra data*/

.extra-data {
  background-color: #e4edff;
}

/* headers in table rows */

td.stats-header {
  background-color: #cdffcb;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* css custom tooltip algemeen */

.tooltip {
  z-index: 999999999;
}

.tooltip-link {
  position: relative;
}

.custom-tooltip {
  /*width: 400px;*/
  /*height: 300px;*/

  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 100;

  padding-bottom: 40px;
  padding-left: 40px;

  max-height: 400px;
  overflow: scroll;

  &.top {
    bottom: 0;
    top: unset;
  }

  .custom-tooltip-content {
    background-color: #ffffff;
    color: #000000;

    padding: 10px 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }
}

.tooltip-link:hover .custom-tooltip {
  display: block;
}

.custom-tooltip:hover,
.custom-tooltip-content:hover {
  display: block;
}

/* css custom tooltip algemeen end */

/* custom tooltip carousel*/

.custom-tooltip .carousel-control-prev,
.custom-tooltip .carousel-control-next {
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-control-prev {
  left: -28px;
}

.carousel-control-next {
  right: -28px;
}

/* custom tooltip carousel end*/

.inline-external-link {
  color: #588cce;
  font-size: 12px;
  padding: 0 4px;
  bottom: 7px;
  position: relative;
}

/* fixed width columns */

.col.fixed-width-300 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  overflow: scroll;
}

.col.fixed-width-400 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 400px;
  //overflow: scroll;
  //overflow: hidden;
}

.col.fixed-width-400 + .col {
  //overflow: scroll;
}

/* PROJECT TABLE AND DETAILS */
.projects_table,
.purchases_table {
  overflow: scroll;
  box-sizing: unset;
}

.projects_table td.mute,
.projects_table td .mute {
  color: #ddd;
}

dl {
  display: flex;
  flex-wrap: wrap;
}

dt {
  /*flex-grow: 0;*/
  /*flex-shrink: 0;*/
  /*flex-basis: 120px;*/

  font-weight: normal;

  width: 33%;
  /*float: left;*/
  /*width: 50%;*/
}

dd {
  margin-left: auto;
  width: 66%;
}

/** Notifications */

#notifications_table {
}

#notifications_table .read {
}

#notifications_table .unread {
  font-weight: bold;
}

.notification.fancybox-content {
}

.notification.fancybox-content strong {
  display: inline-block;
  min-width: 120px;
}

.notification.fancybox-content .message {
  margin-top: 5px;
  border: 1px solid #efefef;
  padding: 20px;
}

/* notificaiotns in header */
.profile-notifications {
  position: relative;
}

.u-pro .unread_count {
  border: 1px solid #ff0000;
  height: 20px;
  background-color: #ff8888;
  position: absolute;

  line-height: 18px;
  border-radius: 30px;
  padding-left: 7px;
  padding-right: 7px;
}

.unread_count.allread {
  background-color: #efefef;
  border-color: #ccc;
  color: #ccc;
}

.profile-notifications .unread_count {
  top: 11px;
  right: 25px;
}

.berichten-item {
  position: relative;
}

.berichten-item .unread_count {
  top: 8px;
  right: 8px;
}

/* multi select plugin fixes */

.ms-parent {
}

.ms-parent .ms-drop {
  width: 400px;
}

.ms-parent {
}

/* leads table general */

.table-leads-general tr.rejected {
  /*text-decoration: line-through;*/
  color: #880000;
  background-color: #fff6f6 !important;
}

/***************************************************************
*
* NEW DESIGN JAN 2020
*
*****************************************************************/

html,
body {
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4.card-title {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.small-col {
  flex-grow: 0;
}

.btn {
  &.btn-primary {
    background: #0086e9;
    border-radius: 55px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding: 15px 30px;

    display: block;
    margin-left: auto;
  }

  &.btn-small,
  &.btn-sm {
    padding: 5px 10px;
    font-size: 15px;
    display: inline-block;
  }

  &.btn-xs {
    padding: 3px 11px;
    line-height: 12px;
    font-size: 12px;
  }
}

.logo-dot {
  display: none;
  position: relative;
  top: 2px;
  left: 5px;
}

.mini-sidebar {
  .logo-dot {
    display: block;
  }

  .logo-full {
    display: none;
  }
}

.fixed-layout .page-wrapper {
  padding-top: 90px;
}

.topbar {
  background-color: #0086e9 !important;

  .navbar-header {
    background: unset !important;
    padding-left: 0 !important;
    line-height: unset !important;

    .navbar-brand {
      display: block;
      //width: 100%;
      margin-right: 10px !important;
      margin-left: 10px !important;

      padding-top: 15px;

      height: 66px;
      display: flex;
      align-items: center;

      border-bottom: 1px solid #ffffff30;

      .logo-full {
        margin-top: 20px !important;
        //  display: block;

        max-width: 160px;
        margin: auto;
        // display: block;
        height: unset;
      }
    }
  }

  .navbar-collapse {
    height: 65px;
    padding-top: 3px;

    .nav-item {
      display: flex;
      align-items: center;

      .nav-link {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      span {
        line-height: unset !important;
      }

      i.support {
        color: #81d21d;
        font-size: 12px;
        margin-right: 3px;
      }

      &.launch-campaign {
        .btn {
          font-size: 18px;
          padding-top: 3px;
          padding-bottom: 3px;
          height: 37px;
          line-height: 26px;
          border-radius: 12px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

.left-sidebar {
  background-color: #0086e9;
  box-shadow: 5px 0px 9px rgba(0, 0, 0, 0.25);

  text: #ffffff;

  .scroll-sidebar {
    height: calc(100% - 3px);
  }

  .sidebar-nav {
    ul {
      display: flex;
      flex-direction: column;

      //margin-top: 30px;

      min-height: calc(
        100vh - 157px
      ); //this is needed to place last two few at the bottom, while keeping the overflow scroll

      li {
        &#sidebar-menu-upgrade {
          //margin-top: auto !important;
        }

        a {
          display: flex;
          padding: 0 10px 0 15px;
        }

        .hide-menu {
          display: flex; //so the pill always stays on the same row
          width: 100%;
          padding-left: 5px;
          align-items: center;
          white-space: nowrap;
        }

        a,
        i {
          color: #fff;
          font-size: 15px;
        }

        &.empty {
          height: 50px;
        }

        &.nav-small-cap {
          color: #fff;
        }
      }
    }

    .badge.badge-pill {
      margin-left: auto;
      padding: 4px 8px 3px 8px;
      margin-top: 1px;
    }
  }

  .copyright {
    margin-top: 20px;
    border-top: 1px solid #ffffff30;
    padding: 10px 10px;

    font-weight: normal;
    font-size: 12px;
    color: #fff;
  }
}

.immowi-background {
  background: linear-gradient(90deg, #f8fdf4 0%, #f9fdff 100%);
}

/* cards */
.card {
  .card-header {
    background: linear-gradient(90deg, #f8fdf4 0%, #f9fdff 100%);
    border-radius: 20px 20px 0px 0px;
    position: relative;

    min-height: 50px;

    &:not(.no-logo) {
      &:after {
        content: "";
        right: 20px;
        top: 0;
        position: absolute;
        //background-image: url("/images/frontend/logo.svg"); //removed again :)
        width: 120px;
        background-size: 120px;
        background-repeat: no-repeat;
        bottom: 0;
        background-position: center center;
      }
    }

    &.square {
      border-radius: unset;
    }

    .card-title,
    .card-subtitle {
      margin-bottom: 0;
    }

    /* nav-items in card header */
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      background: none;
    }
  }

  .card-body {
  }

  .card-footer {
  }
}

/* datatables */

.dataTables_wrapper {
  table {
    border: none;

    th {
      border: 0;
      font-weight: bold;
      // line-height: 40px;
      padding-top: 20px;
      padding-bottom: 20px;

      &:after,
      &:before {
        bottom: 19px !important;
      }
    }

    td {
      border: 0;
      padding: 10px;
      color: #626262;
    }
  }

  .dt-buttons {
    float: right;

    .dt-button {
      display: inline-block;

      background-color: #f8fdf7;
      border: 1px solid #dedede;
      color: #404040;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: normal;

      &:hover {
        background-color: #fefefe;
      }
    }
  }

  .dataTables_filter {
    float: left;
    width: 50%;
    min-width: 250px;

    label {
      display: block;
      font-size: 0;

      position: relative;

      input {
        padding: 10px;
        font-size: 17px;
        border: 1px solid #efefef;
        background: #fefefe;
        border-radius: 6px;
        margin-left: 0 !important;
        margin-bottom: 10px;
        width: 100% !important;

        position: relative;

        &:focus {
          background: #fefefe;
        }
      }

      &:after {
        font-family: "FontAwesome";
        color: red;
        position: absolute !important;
        right: 0;
        content: "\F007";
        top: 0;
        bottom: 0;
        display: block;
        width: 50px;
        height: 50px;
      }
    }
  }

  ul.pagination {
    align-items: center;

    li {
      border: none;
      background-color: none;
      padding: 0 10px;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        background-color: unset !important;
      }

      a.page-link {
        border: none;
        padding: 0;

        &:hover {
          background-color: unset !important;
          text-decoration: underline;
        }
      }

      &.active {
        a.page-link {
          text-align: center;
          width: 25px !important;
          height: 25px !important;
          line-height: 25px !important;
          background-color: #0086e9 !important;
          border-radius: 50% !important;
          border: none !important;
        }
      }
    }
  }

  .dataTables_length {
    padding-top: 3px;
    margin: 0;
    color: #999;
    border: none;
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
    }
  }
}

.container.lightbox {
  width: 80% !important;
  max-width: 650px !important;

  background: linear-gradient(90deg, #f8fdf4 0%, #f2f9fe 100%);
  border-radius: 31.3941px;

  color: #626262;

  padding-left: 35px;
  padding-right: 35px;

  h4.card-title {
    color: #404040;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;

    margin-bottom: 15px;
  }

  h6.card-subtitle {
    color: #404040;
    margin-bottom: 15px;
  }

  h6.card-subtitle-light {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #626262;
    margin-bottom: 15px;
  }

  h4 {
    color: #404040;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 179%;
  }

  label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 191.5%;
    margin-top: 20px;
    color: #404040;
    margin-bottom: 20px;
  }

  .fancybox-close-small {
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
}

.lightbox.comment {
  .comment-list {
    margin-top: 30px;
    margin-bottom: 40px;
    border-radius: 10px;

    .comment-item {
    }
  }
}

/* filter row */

.filters {
  .form-control {
    background-color: #fff;
    height: 40px;
    padding-left: 15px;
    border-radius: 20px;

    &.btn {
      background-color: #0086e9;
      border: none;
      border-radius: 30px;
      font-size: 18px;
    }
  }

  /* for select2*/
  .select2-selection {
    height: 40px;
    border-radius: 20px;
  }

  .expand-select2-height {
    .select2-selection {
      height: unset;
    }
  }

  .clear-filters {
    white-space: nowrap;
  }
}

/* accordions */

.accordion {
  .card {
    margin-bottom: 0;

    &.validated {
      i {
        display: block !important;
      }
    }

    &:not(.validated) {
      .card-header {
        background: #f2f2f2;
      }
    }

    .card-header {
      padding: 3px 10px 0;

      //hide logo
      &:after {
        display: none;
      }

      //check icon
      i {
        display: none;
        float: right;
        color: $green;
        font-size: 20px;
        padding: 10px 5px;
      }
    }

    .card-body {
      button:disabled {
        background-color: #efefef;
        color: #000;
        border: 1px solid #aaa;
      }
    }
  }
}

/* projects overview */

.card.project {
  border-radius: 20px;
  overflow: hidden;

  .card-img-overlay {
    left: unset;
    right: 0;
    top: 0;

    .badge {
      border: 1px solid #000000;
      box-sizing: border-box;

      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      box-shadow: 0px 6px 28px -1px rgba(0, 134, 233, 0.17);
      border-radius: 5px;
      padding: 5px 15px;

      &.red {
        background: linear-gradient(
          90.81deg,
          #ffacac 0.72%,
          #fbd0b1 98%,
          #d84646 199.41%
        );
      }

      &.green {
        background: linear-gradient(
          90.81deg,
          #e0ffd1 0.72%,
          #9fdfa2 98%,
          #64a2cf 199.41%
        );
      }
    }
  }

  .card-title {
    a {
      color: #404040;
    }
  }
}

/* project details */

.project-details-view {
  //.card.project-card {
  //  border-radius: 20px;
  //  overflow: hidden;
  //  padding: 12px;
  //
  //  .card-img-top-img-container {
  //    border-radius: 12px;
  //  }
  //}

  .card.project {
    .card-img-top-img-container {
      margin: 15px;
      border-radius: 10px;
    }

    .card-body {
      .colorful-text {
        text-align: center;
      }

      .project-manager {
        h5 {
          text-align: center;

          background-color: #f8fdf4;
          font-size: 18px;
          padding: 14px 10px;
          color: #404040;

          i {
            color: #626262;
            margin-right: 10px;
          }
        }

        ul {
          padding-top: 10px;
          padding-left: 40px;

          li {
            font-size: 14px;
            color: #626262;
          }
        }
      }

      .contact-immowi {
        background: linear-gradient(46.16deg, #e9f6ff 1.46%, #f0fae4 192.29%);
        border-radius: 10px;
        padding: 10px 10px;

        h5 {
          font-size: 22px;
          color: #404040;
          text-align: center;

          img {
            padding-left: 5px;
            vertical-align: bottom;
            height: 25px;
          }
        }

        ul {
          text-align: center;

          li {
            color: #0086e9;
            font-size: 14px;

            i {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  .project-details {
    border-radius: 20px;
    padding: 20px;

    .left {
      .data-field-list-container {
        min-height: 80px;

        ul {
          li {
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;

            display: inline-block;
            padding: 5px 15px;
            margin-right: 10px;
            margin-bottom: 10px;

            background: #f8fdf4;
            box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.08);
            border-radius: 41px;
          }
        }
      }

      .btn {
        width: max-content;
      }
    }

    .right {
      text-align: center;
    }

    .landingpage-button {
      padding: 10px 15px;
      border: 1px solid #aaa;

      box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.08);
      border-radius: 56px;
    }

    .cost-per-lead {
      margin-top: 30px;

      .euro {
        display: block;
        font-size: 46px;
        line-height: 46px;
        font-weight: bold;
      }

      .text {
      }
    }
  }
}

/* big messages*/

.nothing-found {
  position: relative;
  margin: 130px 50px;

  span {
    display: block;
    text-align: center;
    color: #8e8e8e;
    font-size: 40px;
    font-family: "Josefin Sans", sans-serif;
    z-index: 99;
    position: relative;
  }

  i {
    color: #efefef66;
    font-size: 180px;
    position: absolute;
    left: 0;
    top: -60px;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 1;
  }
}

.please-upgrade {
  display: flex;
  border: 1px solid #efefef;
  padding: 20px;
  position: relative;
  margin: 20px;
  margin-top: 30px;

  width: 100%;

  &.upgrade-page {
    border: none;
  }

  .content {
    display: flex;
    align-items: center;

    max-width: 700px;
    margin: auto;

    &.large {
      padding: 60px;
      width: 100%;

      i {
        font-size: 100px;
      }

      span {
        font-size: 26px;
        line-height: 46px;

        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  span {
    display: block;
    text-align: center;
    color: #8e8e8e;
    font-size: 21px;
    font-family: "Josefin Sans", sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 26px;

    a {
      color: #8e8e8e;
    }
  }

  i {
    color: #0086e9;
    font-size: 50px;
    text-align: center;
  }
}

.please-upgrade-page {
  .actions {
    color: #8e8e8e;
    font-size: 16px;
    font-family: "Josefin Sans", sans-serif;

    .action {
      border-top: 1px solid #dedede;
      padding-top: 30px;
      padding-bottom: 30px;

      font-family: "Lato", sans-serif;
      font-size: 15px;
      text-align: center;

      &:first-of-type {
        border-right: 1px solid #dedede;
      }

      a {
        padding-top: 30px;
        padding-bottom: 30px;

        display: block;
        width: 100%;

        &:hover {
          background-color: #efefef;
        }
      }

      i {
        color: #8e8e8e;
        font-size: 40px;
        padding-bottom: 20px;
        text-align: center;
      }

      h4 {
        color: #8e8e8e;
      }
    }
  }
}

/* site-wide choose a plan link */
a.choose-plan-link {
  //color: #81D21D;
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;

  background: linear-gradient(to right, #81d21d 10%, #0086e9 90%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* dashboard-stats */

.card.dashboard-projects-card {
  height: calc(100% - 20px);

  .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dashboard-stats {
  .monthly-leads {
    font-size: 45px;
  }

  .month-year {
  }

  .campaigns {
    color: #404040;

    font-size: 18px;
    font-weight: bold;
    text-align: right;

    .live {
      border-radius: 6px;
      border: 1px solid #000;
      padding: 3px 10px;
      box-shadow: 0px 6px 28px -1px rgba(0, 134, 233, 0.17);
      background: linear-gradient(
        90.81deg,
        #e0ffd1 0.72%,
        #9fdfa2 98%,
        #64a2cf 199.41%
      );
      color: #000;
      margin-left: 5px;
    }
  }
}

.balance {
  .total {
    font-size: 39px;
  }
}

.table {
  i.fa-check-square,
  i.fa-check-circle {
    color: #81d21d;
  }

  i.fa-times-circle {
    color: #e3342f;
  }

  .badge-new {
    padding: 2px 9px;
    margin-left: 5px;
  }

  &.slim-table {
    tr {
      td {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }
}

/* tour over popup ligthbox */

#_PTJS_WRAPPER {
  z-index: 999999999;
  position: relative;
}

/* general show child on hover class */

.show-child-on-hover {
  .show-child {
    display: none;
  }

  &:hover {
    .show-child {
      display: inline-block;
    }
  }
}

/* EDITABLE CONTENT COMPONENTS */

/* for editable content containers - to position pencil and full hover area */
.has-editable-content {
  position: relative;

  &.card-body {
    padding: 0;
  }
}

/* to show edit pencil on hover */
.editable-content-container {
  padding: 1.25rem;
  position: relative;

  .edit-pencil {
    display: block;
    font-size: 16px;
    transition: transform 0.2s ease;
  }

  &:hover .edit-pencil {
    transform: scale(1.2);
  }

  &.show-edit-on-hover {
    .edit-pencil {
      display: none;
    }

    &:hover .edit-pencil {
      display: block;
      transform: scale(1.2);
    }
  }
}


//for image edit
.image-card .edit-pencil {
  background-color: #efefef;
  border: 1px solid #333;
  color: #333;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  line-height: 30px;
  text-align: center;
}

/* END - EDITABLE CONTENT COMPONENTS */

/* general show edit pencil component on hover */

.has-edit {
  position: relative;

  .show-edit-on-hover {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    .show-edit-on-hover {
      display: inline-block;
    }
  }
}

.has-link {
  position: relative;

  .show-link-on-hover {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    .show-link-on-hover {
      display: inline-block;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.link {
  color: #fb9678;
  cursor: pointer;

  &:hover {
    color: #005a9d;
  }
}

/* inline edit component */

.inline-edit-component {
  position: relative;
  z-index: 10; //needed for when used in sidepanel
  max-width: 400px;

  .edit-mode {
    input {
      //height: 30px;
      min-height: unset;
    }

    .save-cancel {
      flex-direction: column;

      i {
        font-size: 15px;
        cursor: pointer;
      }

      .save {
      }

      .cancel {
      }
    }

    .errors {
      color: red;
    }
  }

  .view-mode {
    .click-to-edit {
      display: none;
      cursor: pointer;
      margin-left: 10px;
    }

    &:hover {
      .click-to-edit {
        display: inline-block;
      }
    }
  }
}

/* role = button as link */
span[role="button"] {
  color: #fb9678;
}

.swal2-container.swal2-shown {
  z-index: 99999;
}

/* better badges */

.badge-outline {
  background-color: unset !important;
  border-width: 1px;
  border-color: #6c757d;
  border-style: solid;
  color: #6c757d !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;

  &.badge-warning {
    border-color: #ffed4a;
  }
  &.badge-danger {
    border-color: #e3342f;
  }
  &.badge-info {
    border-color: #6c757d;
  }

  &.badge-primary {
    border-color: #0086e9;
  }
  &.badge-secondary {
    border-color: #6c757d;
  }
  &.badge-success {
    border-color: #38c172;
  }
}

.badge-combine-first {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-right: -4px;
}

.badge-combine-second {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/*
 OFF CANVAS WIDGET
 - not included with our vwersion of EliteAdmin.
 - see: http://eliteadmin.themedesigner.in/demos/bt4/inverse-php/ui-offcanvas.php
 */

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dedede;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  //padding:1rem 1rem;
  //overflow-y: auto;

  padding: 0 !important;
  overflow-y: scroll;
  max-height: 100vh;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 540px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-end.issecondpanel {
  width: 490px;
}

.offcanvas .close-button {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 99;
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
  display: none;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
  display: block;
}

/* offcanvas lead details */

.offcanvas.lead-details-panel {
  .request-refund {
    font-size: 14px;
    line-height: 16px;
  }
  //.project-info {
  //
  //  border: 1px solid #dedede;
  //  padding: 10px;
  //  border-radius: 6px;
  //  padding-top: 3px;
  //}

  .add-comment {
    width: 100%;
    display: flex;
    padding-left: 12px;
    padding-bottom: 15px;

    position: relative;

    .text {
      //padding-left: 15px;
      //font-size: 14px;

      padding-left: 23px;
      font-size: 16px;
      //color: #f6993f;
    }

    .icon {
      position: relative;

      z-index: 9;
    }

    &::before {
      /*border-left: 1px solid red;*/
      /*content: " ";*/
      /*height: 100%;*/

      content: "";
      border: 3px solid #b8b8b8;
      width: 4px;
      height: 100%;

      position: absolute;
      left: 17px;
      top: 16px;

      z-index: 1;
    }
  }

  .lead-comments-accordion {
    .card {
      overflow: visible !important;
      position: relative;
      margin-left: 50px;
      //padding-left: 20px;

      border: none;
    }

    .card::before {
      /*border-left: 1px solid red;*/
      /*content: " ";*/
      /*height: 100%;*/

      content: "";
      border: 3px solid #b8b8b8;
      width: 4px;
      height: 100%;
      position: absolute;
      left: -33px;
    }

    .icon {
      position: absolute;
      left: -50px;
      display: block;
      width: 40px;
      height: 40px;
      padding: 0px;
      border-radius: 50%;
      text-align: center;
      float: left;
      z-index: 9;
    }

    .badge.date {
      height: 21px;
    }

    .content {
      //border: 1px solid #dedede;
      //padding: 5px;
      //margin-bottom: 20px;
    }

    .status.when-collapsed {
      display: none;
    }

    .collapsed {
      .status.when-active {
        display: none;
      }

      .status.when-collapsed {
        display: block;
      }

      .actions {
        display: none;
      }
    }

    //.icon i {
    //  width: 30px;
    //  height: 30px;
    //  border: 10px solid #b8b8b8;
    //  border-radius: 30px;
    //
    //}
  }
}

// new compacter tables

.table-responsive {
  td {
    .primary-info {
      dt {
        color: #888;
      }
      dd {
        color: #333;
        margin-bottom: 0;
      }
    }

    .secondary-info {
      display: block;
      color: #888;
      font-size: 15px;
    }
    .campaign_lead-cell {
      .primary-info {
        font-size: 18px;
        color: #fb9678;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .crm_lead-cell {
      .primary-info {
        font-size: 18px;
        color: #fb9678;

        &:hover {
          text-decoration: underline;
        }
      }
      .secondary-info {
        font-size: 15px;

        .email {
          margin-right: 5px;
          padding-right: 5px;
          // border-right: 1px solid #aaa;
        }
      }

      .right {
        max-width: 100px;
        text-align: right;
      }
    }

    .crm-lead-cell {
    }
  }
}

// sidebar sub-headers
h4.card-block-heading {
  font-size: 22px;
  line-height: 22px;

  margin-bottom: 5px;
}

h6.card-block-subheader {
  color: #888;
  margin-bottom: 15px;
}

// TABS IN SIDEBAR

.left-sidebar {
  h4 {
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    padding-left: 10px;
    padding-top: 5px;
  }

  li {
    margin-bottom: 10px;
  }

  .nav-tabs {
    margin-top: 10px;

    i {
      color: #fff;
      font-size: 24px;
    }

    .nav-link {
      margin-bottom: 5px;
      background: none;
      padding-bottom: 0;

      // padding: 5px 150px;
      margin-bottom: 0;

      &.active {
        border: 1px dashed #fff;
      }
    }
  }
}

// MODAL

.modal {
  &.show {
    display: block;
  }

  &.bigmodal {
    .modal-dialog {
      width: 90%;
      max-width: 960px;
    }

    .modal-body {
      min-height: 200px;
    }
  }

  &.editor-modal {
    .modal-dialog {
      width: 90vw;
      min-width: 600px;
      max-width: unset;

      height: 90vh;
      min-height: 400px;
    }

    .modal-content {
      height: 100%;
    }
  }
}

.datepicker table tr td.today {
  background-color: #6cb2eb !important;

  &:hover {
    background-color: #90bde1 !important;
  }
}

// rappasoft bulk actions
.dropdown-menu.dropdown-menu-right.w-100 {
  left: unset !important;
  right: -160px;
  width: fit-content !important;
}

.datasuite-filters {
  .filter-container {
    padding: 10px;
    border: 1px solid #dedede;
    background-color: #f8f8f8;
    margin-bottom: 7px;
    border-radius: 8px;
  }

  .and {
    border: 1px solid #dedede;
    background-color: #efefef;
    padding: 0 7px 2px;
    border-radius: 10px;
    font-size: 17px;
  }

  .add {
  }

  .filter {
    .filter-settings {
      .filter-setting {
        border: 1px dashed #999;
        padding: 2px 10px;
        margin-bottom: 10px;

        .key {
        }
        .operator {
          border: 1px solid #999;
          border-radius: 100px;
          padding: 0px 9px;
        }
        .value {
        }
      }
    }
  }

  .filter-component {
    .and {
      padding: 0px 6px;
      font-size: 12px;
      color: #444;
      border-radius: 2px;
      margin-left: 20px;
      margin-top: -16px;
      margin-bottom: -5px;
    }

    .add {
      margin-left: 20px;
      margin-top: -14px;
    }
  }
}

//for spinning

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}

.ptjs-theme.classic .step-container .step-button-close,
.ptjs-theme.classic .step-container .step-footer .step-buttons span {
  background-color: #0086e9 !important;
}

.ptjs-theme.classic .step-container .step-footer .step-pagination,
.ptjs-theme.classic .step-container .step-header .title {
  color: #0086e9 !important;
}

.ptjs-theme.classic .step-container .step-button-close {
  right: 20px !important;
  z-index: 9;
}
// radius and arrow adjustment

.ptjs-theme.classic .step-container {
  border-radius: 20px;
}

.step-buttons {
  position: relative;
  z-index: 9;
}

.ptjs-theme.classic.step-arrow-bc-r::after,
.ptjs-theme.classic.step-arrow-bl::after,
.ptjs-theme.classic.step-arrow-cb-r::after {
  border-width: 0 30px 30px 0 !important;
}

.ptjs-theme.classic.step-arrow-ct-l::after,
.ptjs-theme.classic.step-arrow-tc-l::after,
.ptjs-theme.classic.step-arrow-tr::after {
  border-width: 30px 0 0 30px !important;
}

.ptjs-theme.classic.step-arrow-bc-l::after,
.ptjs-theme.classic.step-arrow-br::after,
.ptjs-theme.classic.step-arrow-cb-l::after {
  border-width: 0 0 30px 30px !important;
  right: 0;
}

.ptjs-theme.classic.step-arrow-rb::after,
.ptjs-theme.classic.step-arrow-rm-t::after {
  border-width: 30px 30px 0 0 !important;
}

// for soe reasong this is display:hidden by default
.invalid-feedback {
  display: inline-block !important;
}
