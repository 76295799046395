// Sidebar

/*******************
Main sidebar
******************/

.left-sidebar {
    position: absolute;
    width: 220px;
    height: 100%;
    top: 0px;
    z-index: 20;
    padding-top: 70px;
    background: $sidebar;
    box-shadow: $shadow; 
}
 

/*******************
use profile section
******************/
.user-pro{
    margin-top: 20px; 
    a{
        img{
            width: 30px;
            margin-right: 10px;
        }
    }
}

/*******************
sidebar navigation
******************/

.scroll-sidebar {
    height: calc(100% - 27px); 
    position: relative;
    &.ps .ps__scrollbar-y-rail {
        left: 2px;
        right: auto;
        background: none;
        width: 6px;
        /* If using `left`, there shouldn't be a `right`. */
    }
}

.collapse.in {
    display: block;
}

.sidebar-nav {
    
    padding: 15px 0 0 0px;
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style: none; 
            a {
                color: $sidebar-text;
                padding: 10px 35px 10px 15px;
                display: block;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                &.active,
                &:hover {
                    color: $themecolor;
                    i {
                        color: $themecolor;
                    }
                }
                &.active {
                    font-weight: 500;
                    color: $gray-800;
                }
            }
            ul {
                padding-left: 30px;
                li a {
                    padding: 7px 35px 7px 15px;
                }
                ul {
                    padding-left: 15px;
                }
            }
            &.nav-small-cap {
                font-size: 12px;
                margin-bottom: 0px;
                padding: 30px 14px 14px 0px;
                color: $text-muted;
                font-weight: 500;
            }
            &.nav-devider {
                height: 1px;
                background: $border-color;
                display: block;
                margin: 15px 0;
            }
        }
    }
    > ul > li > a {
        border-left: 3px solid transparent;
        i {
            width:25px;
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
            color: $sidebar-icons;
        }
        .badge {
           float: right;
        }
    }
    > ul > li {
        margin-bottom: 8px;
        margin-top: 8px;
        &.active > a {
            color: $themecolor;
            font-weight: 500;
             border-left: 3px solid $themecolor;
            i {
                color: $themecolor;
            }
        }
    }
    .waves-effect {
        transition: none;
        -webkit-transition: none;
        -o-transition: none;
    }
}

.sidebar-nav .has-arrow {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: $sidebar-text;
        right: 20px;
        -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
        -o-transform: rotate(135deg) translate(0, -50%);
        transform: rotate(135deg) translate(0, -50%);
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        top: 21px;
        -webkit-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }
}

/*.sidebar-nav .active > .has-arrow::after,*/
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    top: 42%;
    width: 7px;
    transform: rotate(-135deg) translate(0, -50%);
}